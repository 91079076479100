.page.content--notfound {
	color:#000;
}

	.page.content--notfound .aligner {
		position:absolute;
		left:0;
		top:0;
		height:100%;
		width:100%;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100%;
	}

		.page.content--notfound .aligner .center {
			width:400px;
   	 		text-align:center; 
		}
	
	.page.content--notfound h2 {
		float:left;
		width:100%;
		font-size:14px;
		margin-top:20px;
	}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
  
}