/*
Colors
Colors
Colors
Colors
*/
.color-white {
	color:#fff !important;
}
.color-black {
	color:#000 !important;
}
.color-green {
	color:#23a638 !important;
}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
}
