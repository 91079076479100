.form {
	color:#000;
}

.form a {
	color:#000;
}

.form .form-row .label {
	color:#000;
	margin-bottom:5px;
	float:left;
	width:100%;
	font-weight:bold;
	font-size:16px;
}.checkbox_specific {
		float:left;
		width:36px;
		height:31px;
		border:1px solid #888; 
		background-color:#ccc;
		background-size:cover;
		background-position:top;
		background-repeat:no-repeat;
		cursor:pointer;

		&.active {
			
		}

	}

.form .element-row .indicator {
	position:absolute;
	top:0;
	right:0;
	height:100%;
	width:2px;
	background-color:#000;
}

	.form .element-row label {
		float:left;
		font-size:12px;
		color:#000;
		font-weight:bold;
		margin-bottom:5px;
		margin-top:5px;
	}
	
	.form .element-row .form-input {
		padding:7px 15px;
		box-shadow: inset 0px 0px 7px -4px rgba(0,0,0,1);
		border:1px solid #888;
		font-size:12px;
		line-height:16px;
	}
	
	.form .element-row .upload .left {
		width:200px;
		min-height:109px;
		background-color:#e1e1e1;
		border-radius:4px;
		text-align:center;
	}
	
		.form .upload-info {
			float:left;
			width:100%;
			text-align:center;
			font-size:13px;
		}
	
	.form .element-row .indicator.ok {
		background-color:#84b50f;
	}
	
	.form .form-submit {
		float:right;
		margin:0;
		text-transform:uppercase;
		transition: all 0.1s ease-in-out;
		background-color:#000;
		color:#fff;
		cursor:pointer;
		
		margin-top:20px;
		padding-top:10px;
		padding-bottom:10px;
		padding-left:20px;
		padding-right:20px;
		font-family:'Noto Sans JP',arial;
		font-size:15px;
		line-height:20px;
		background-color:#639a0b;
		border-radius:10px;

		
	}
	
	.form .form-cancel {
		float:right;
		margin:0;
		margin-top:10px;
		margin-right:5px;
		padding:10px 15px;
		padding-right:17px;
		font-size:18px;
		font-weight:bold;
		text-transform:uppercase;
		font-family:'Open Sans',arial;
		transition: all 0.1s ease-in-out;
		background-color:#cdcdcd;
		color:#fff;
		cursor:pointer;
	}
	
	.form .form-submit:hover,
	.form .form-cancel:hover {
		text-decoration:underline;
	}
	
.thanks {
	font-family:'Open Sans',arial;
	color:#000;
	text-align:center;
}

/*
AKKOORD
*/
.akkoord {
	clear:both;
	float:left;
	width:100%;
	margin-top:10px;
	margin-bottom:10px;
	font-size:13px;
	line-height:20px;
}

	.akkoord .left {
		float:left;
		width:20px;
	}
	
		.akkoord .left input[type="checkbox"] {
			opacity: 1;
			/* position: absolute; */
		}
		.akkoord .left input[type="checkbox"] + label {
			text-align:center;
			cursor:pointer;
		}
		.akkoord .left input[type="checkbox"]:focus + label {
			background-color:transparent;
		}
		.akkoord .left input[type="checkbox"] + label div {
			display:inline-block;
			line-height: 16px;
			font-size: 12px;
			height: 16px;
			width: 16px;
			margin:-0px 4px 0 0;
			border: 1px solid black;
			color: transparent;
		}
		.akkoord .left input[type="checkbox"]:checked + label div {
			color: black;
		}

	.akkoord .right {
		float:left;
		width:300px;
	}
	
/**
Form specific radiobuttons
**/

.form .form-label {
	float:left;
	width:100%;
	margin-bottom:5px;
	font-family:'Open Sans',arial;
	font-weight:bold;
}

	.form .radiobutton {
		display:inline-block;
	}
	
	.form .radiobutton_specific-element {
		float:left;
		width:100%;
		margin-bottom:10px;
		padding-bottom:10px;
		border-bottom:1px solid #ccc;
	}		
		.form .radiobutton_specific-element .radiobutton_specific_left {
			float:left;
			width:40px;
		}
		
		.form .radiobutton_specific-element .radiobutton_specific_right {
			float:left;
			margin-left:10px;
			width:450px;
		}
	
	
	
		.form .radiobutton_specific-element:last-child {
			border-bottom:0;
		}
		
/**
Radiobuttons: specific
**/		
.form .radiobutton_specific {
	float:left;
	width:30px;
	height:30px;
	border:1px solid #888;
	background-color:rgba(0,0,0,0.05);
	box-shadow:0 0 5px rgba(0,0,0,0.1);
	border-radius:5px;
	background-size:cover;
	background-position:top;
	background-repeat:no-repeat;
	cursor:pointer;
}

	.form .radiobutton_specific.active {
		background-position:center;
		background-repeat:no-repeat;
		background-image:url('../img/static/icon-check.svg');
		background-size:65%;
	}
	
/**
Checkbox: specific
**/
.form .checkbox_specific {
	float:left;
	width:30px;
	height:30px;
	border:1px solid #888;
	background-color:rgba(0,0,0,0.05);
	box-shadow:0 0 5px rgba(0,0,0,0.1);
	border-radius:5px;
	background-size:cover;
	background-position:top;
	background-repeat:no-repeat;
	cursor:pointer;
}

	.form .checkbox_specific.active {
		background-position:center;
		background-repeat:no-repeat;
		background-image:url('../img/static/icon-check.svg');
		background-size:65%;
	}

.form .checkbox_specific_label_ready { 
  float:left;
  margin-left:10px;
  margin-top:7px;
  width:500px;
}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.form .form-submit {
		float:right !important;
		font-size:15px !important;
	}

	.checkbox_specific_label_ready { 
	  margin-top:0 !important;
	  width:160px !important;
	  font-size:13px;
	}

}
