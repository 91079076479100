header {
	display:none;
	position:relative;
	float:left;
	width:100%;
	background-color:#23a638;
}

	header .desktop {
		height:115px;
	}

	header .logo {
		position:absolute;
		left:15px;
		top:0;
		width:200px;
		height:115px;
		background-color:#fff;
		background-image:url(../img/static/logo-gonxxt.svg);
		background-position:center;
		background-repeat:no-repeat;
		background-size:70%;
		z-index:20;
		cursor:pointer;
	}
	
	/*
	Counter
	Counter
	Counter
	Counter
	*/
	header .counter {
		position:absolute;
		top:-34px;
		right:20px;
		z-index:3000;
	}
		header .counter span.outer {
			position:absolute;
			width:28px;
			height:28px;
			background-color:#ffd800;
			border:1px solid #000;
			border-radius:50%;
		}
		
		header .counter  span.inner {
			position:absolute;
			left: -1px;
			top: 6px;
			width:100%;
			text-align:center;
			font-size:11px;
			color:#000;
			font-weight:bold;
		}
	
	/*
	Top
	Top
	Top
	Top
	*/
	header .top {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		background-color:#fff;
		padding:10px;
		box-shadow:0 3px 3px rgba(0,0,0,0.3);
		z-index:1;
	}
	
		header .top .rating {
			float:left;
			margin-top:6px;
			margin-left:70px;
			background-size:contain;
			background-repeat:no-repeat;
		}
		
			header .top .rating span.rate {
				float:left;
				margin-top:-1px;
				width:27px;
				height:27px;
				background-color:rgba(0,0,0,0.1);
				border: 1px solid rgba(0,0,0,0.2);
				border-radius:50%;
			}
			
				header .top .rating span.rate span {
					float:left;
					margin-left:4px;
					margin-top:6px;
					font-size:12px;
					font-weight:bold;
					color:#000;
				}
			
				header .top .rating span.image {
					float:left;
					margin-left:10px;
					margin-top:-3px;
					width:100px;
					height:30px;
					background-image:url(../img/static/google-stars.png);
					background-repeat:no-repeat;
					background-position:center;
					background-size:contain;
				}
	
		header .top .contact {
			float:right;
		}
	
			header .top .contact ul {
				float:right;
				padding:0;
				margin:0;
				list-style-type:none;
			}
			
				header .top .contact ul li {
					float:left;
					padding:10px;
					color:#888;
				}
				header .top .contact ul li:last-child {
					padding-right:0;
				}
				
				header .top .contact ul li a {
					font-family:'Roboto',arial;
					font-weight:bold;
					color:#888;
					letter-spacing:0.5px;
					text-decoration:none;
					font-weight:normal;
				}
				header .top .contact ul li a:hover,
				header .top .contact ul li a.active {
					text-decoration:underline;
				}
				
				header .top .cta {
					padding:5px;
					padding-left:15px;
					padding-right:15px;
					border-radius:5px;
					color:#fff;
					background-color:#23a638;
				}
				
					header .top .cta strong {
					}
	
	/*
	Bottom
	Bottom
	Bottom
	Bottom
	*/
	header .bottom {
		position:absolute;
		bottom:0;
		left:0;
		width:100%;
		background-color:#23a638;
		padding:10px;
		z-index:5;
	}
	
	header .bottom nav {
		float:left;
	}
	
			header .bottom nav ul {
				float:right;
				padding:0;
				margin:0;
				list-style-type:none;
			}
			
				header .bottom nav ul li {
					float:left;
					padding:10px;
					padding-left:15px;
					padding-right:15px;
				}
				
				header .bottom nav ul li:last-child {
					padding-right:0;
				}
				
				header .bottom nav ul li a {
					position:relative;
					font-family:'Roboto',arial;
					font-weight:normal;
					color:#fff;
					letter-spacing:0.5px;
					text-decoration:none;
				}
				header .bottom nav ul li a:hover,
				header .bottom nav ul li a.active {
					text-decoration:underline;
				}

header .mobile {
	position:fixed;
	bottom:0;
	left:0;
	width:100%;
	height:60px;
	box-shadow:0 0 10px rgba(0,0,0,0.6);
	background-color:#23a638;
	z-index:100;
}

header .mobile-logo {
	float:left;
	width:80px;
	height:60px;
	background-image:url(../img/static/logo-gonxxt.svg);
	background-repeat:no-repeat;
	background-position:center;
	background-color:#fff;
	background-size:75%;
}

header .mobile-contact {
	float:left;
	margin-top:2px;
	margin-left:20px;
	color:#fff;
	font-size:14px;
	line-height:15px;
}

	header .mobile-contact ul {
		list-style-type:none;
		margin:0;
		margin-top:5px;
		padding:0;
	}
			
	header .mobile-contact ul li {
		padding:0;
		margin:0;
		margin-top:8px;
	}
	
	header .mobile-contact ul li a {
		color:#fff;
	}
	
	header .mobile .menu-button {
		float:right;
		position:relative;
		margin-right:20px;
		margin-top:10px;
		width:40px;
		height:40px;
		background-color:#000;
		border-radius:50%;
		cursor:pointer;
	}
	
		header .mobile .menu-button .line {
			position:absolute;
			width:25px;
			height:2px;
			background-color:#fff;
		}
		
		header .mobile .menu-button .line.line-1 {
			top:12px;
			left:7px;
		}
		header .mobile .menu-button .line.line-2 {
			top:18px;
			left:7px;
		}
		header .mobile .menu-button .line.line-3 {
			top:24px;
			left:7px;
		}
				
			
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
	header .logo {
		width:150px;
	}
	
	header .counter {
		margin-left:37px;
	}
	
	header .top .contact {
		font-size:13px;
	}
	
	header .top .rating {
		margin-left:25px;
	}
	
	header .bottom nav ul li {
		padding-left: 9px;
		padding-right: 9px;	
	}
	
		header .bottom nav ul li a {
			letter-spacing:0;
		}
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {	

}
