/*
Version 0.101

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

/* NOTIFIER */
/* NOTIFIER */
/* NOTIFIER */
/* NOTIFIER */

.notifier {
	display:none;
	position:fixed;
	left:0;
	top:29px;
	width:100%;
	text-align:center;
	z-index:8000;
}

	.notifier .notifier-message {
		display:inline-block;
		padding:5px 10px;
		color:#000;
		font-size:12px;
		text-align:center;
		background-color:#fff;
		border-radius: 3px;
		box-shadow:0 0 8px rgba(0,0,0,0.7);
	}

.notifier-connection {
	position:fixed;
	font-family:arial;
	left:0;
	bottom:-100px;
	width:100%;
	text-align:center;
	z-index:12000;
}

	.notifier-connection .notifier-message {
		display:inline-block;
		padding:10px 20px;
		color:#000;
		font-size:14px;
		font-weight:bold;
		text-align:center;
		background-color:#fff;
		box-shadow:0 0 8px rgba(0,0,0,0.7);
	}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
  
}