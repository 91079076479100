/*
Version 0.122

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2019
	18-04-2019
	- [ADDED] New helper: Table
2018
	03-04-2018
	- [ADDED] .ml-20 for extra margin left
	18-02-2018
	- [ADDED] .click-through added for click through items
	05-02-2018
	- [ADDED] .clickable added for making elements use cursor:pointer.
2017
	08-12-2017
	- [ADDED] Extended the .padding-x and .margin-x styles.
	- [CSS] Added: .mt-5 for 5 pixels margin top
	03-11-2017
	- [CSS] Added: new helper for table-responsiveness: .table-responsive.
	24-10-2017
	- [CSS] Added: some new helpers for mobile margin top: .mt-xs-0 and .mb-xs-0
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

/* HELPERS */
/* HELPERS */
/* HELPERS */
/* HELPERS */

.clear { clear:both; }

.desktop { display:block; }
.mobile { display:none; }

.hidden { display:none; }

.inline {
	display:inline-block;
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Chrome/Safari/Opera */
	-khtml-user-select: none;    /* Konqueror */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none;           /* Non-prefixed version, currently not supported by any browser */
	outline: none;
}

.table-responsive {
	overflow-x:auto;
}

	.table-responsive::-webkit-scrollbar {
    	-webkit-appearance: none;
	}
	
	.table-responsive::-webkit-scrollbar:vertical {
		width: 12px;
	}
	
	.table-responsive::-webkit-scrollbar:horizontal {
		height: 12px;
	}
	
	.table-responsive::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, .5);
		border-radius: 10px;
		border: 2px solid #ffffff;
	}
	
	.table-responsive::-webkit-scrollbar-track {
		border-radius: 10px;  
		background-color: #ffffff; 
	}
	
/**
INTERACTION
INTERACTION
INTERACTION
INTERACTION
**/

.cursor-pointer {
	cursor:pointer;
}

.click-through {
	pointer-events: none;
}

.helper-table {
	overflow-x:auto;
}

/* MARGINS & PADDING */
/* MARGINS & PADDING */
/* MARGINS & PADDING */
/* MARGINS & PADDING */

.nmt {
	margin-top:0;
}
.nmb {
	margin-bottom:0;
}
.ntp {
	padding-top:0;
}
.ntb {
	padding-bottom:0;	
}

.mt-5 {
	margin-top:5px;
}
.mt-10 {
	margin-top:10px;
}
.mt-20 {
	margin-top:20px;
}
.mt-30 {
	margin-top:30px;
}
.mt-40 {
	margin-top:40px;
}
.mt-50 {
	margin-top:50px;
}
.mt-60 {
	margin-top:60px;
}
.mt-70 {
	margin-top:70px;
}
.mt-80 {
	margin-top:80px;
}

.mb-10 {
	margin-bottom:10px;
}
.mb-20 {
	margin-bottom:20px;
}
.mb-30 {
	margin-bottom:30px;
}
.mb-40 {
	margin-bottom:40px;
}
.mb-80 {
	margin-bottom:80px;
}

.ml-20 {
	margin-left:20px;
}
.ml-40 {
	margin-left:40px;
}

.padding-10 {
	padding:10px;
}
.padding-20 {
	padding:20px;
}
.padding-30 {
	padding:30px;
}
.padding-40 {
	padding:40px;
}

.margin-10 {
	margin:10px;
}
.margin-20 {
	margin:20px;
}
.margin-30 {
	margin:30px;
}
.margin-40 {
	margin:40px;
}

.pt-0 {
	padding-top:0;
}
.pt-20 {
	padding-top:20px;
}
.pt-30 {
	padding-top:30px;
}
.pt-40 {
	padding-top:40px;
}
.pt-50 {
	padding-top:50px;
}
.pt-60 {
	padding-top:60px;
}
.pt-70 {
	padding-top:70px;
}
.pt-80 {
	padding-top:80px;
}

.pb-10 {
	padding-bottom:10px;
}
.pb-20 {
	padding-bottom:20px;
}
.pb-30 {
	padding-bottom:30px;
}
.pb-40 {
	padding-bottom:40px;
}

/**
IMAGES
IMAGES
IMAGES
IMAGES
**/

.img-maxwidth {
	max-width:100%;	
}

.img-circle {
	border-radius:50%;
}

/**
ROTATE
ROTATE
ROTATE
ROTATE
**/

.rotate180 {
	transform:rotate(180deg);
}
.rotate-180 {
	transform:rotate(-180deg);
}
.rotate90 {
	transform:rotate(90deg);
}
.rotate-90 {
	transform:rotate(-90deg);
}

/**
FLOATS
FLOATS
FLOATS
FLOATS
**/

.float-left {
	float:left;
}

.float-right {
	float:right;
}

.float-none {
	float:none;
}

/**
ALIGN
ALIGN
ALIGN
ALIGN
**/

.align-left {
	text-align:left !important;
}
.align-center {
	text-align:center !important;
}
.align-right {
	text-align:right !important;
}


/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	
	.desktop { display:none; }
	.mobile { display:block; }
	
	/**
	Margin top MD
	**/
	.mt-md-10 {
		margin-top:10px !important;
	}
	.mt-md-20 {
		margin-top:20px !important;
	}
	.mt-md-30 {
		margin-top:30px !important;
	}
	.mt-md-40 {
		margin-top:40px !important;
	}
	.mt-md-80 {
		margin-top:80px !important;
	}
	
	.mb-md-10 {
		margin-bottom:10px !important;
	}
	.mb-md-20 {
		margin-bottom:20px !important;
	}
	.mb-md-30 {
		margin-bottom:30px !important;
	}
	.mb-md-40 {
		margin-bottom:40px !important;
	}
	.mb-md-80 {
		margin-bottom:80px !important;
	}
	
}

@media (max-width: 768px) {
	
	.min-width {
		min-width:300px;
	}
	
	.mt-40 {
		margin-top:20px;
	}
	.mb-40 {
		margin-bottom:20px;
	}
	.mb-80 {
		margin-bottom:40px;
	}
	
	/**
	Margin top XS
	**/
	.mt-xs-0 {
		margin-top:0 !important;
	}
	.mt-xs-10 {
		margin-top:10px !important;
	}
	.mt-xs-20 {
		margin-top:20px !important;
	}
	.mt-xs-30 {
		margin-top:30px !important;
	}
	.mt-xs-40 {
		margin-top:40px !important;
	}
	.mt-xs-80 {
		margin-top:80px !important;
	}
	
	.mb-xs-0 {
		margin-bottom:0 !important;
	}
	.mb-xs-10 {
		margin-bottom:10px !important;
	}
	.mb-xs-20 {
		margin-bottom:20px !important;
	}
	.mb-xs-30 {
		margin-bottom:30px !important;
	}
	.mb-xs-40 {
		margin-bottom:40px !important;
	}
	.mb-xs-80 {
		margin-bottom:80px !important;
	}
  
}