.block-usps {
	float:left;
	width:100%;
	padding-top:40px;
	padding-bottom:40px;
	min-height:400px;
	background-color:#fff;
}
	.block-usps .img-maxwidth {
		border-radius:10px;
	}
	
	.block-usps .textblock-1 p {
		color:#000;
	}
	
		.block-usps .textblock-1 h3 {
			color:#000;
			font-size:25px;
		}
	
		.block-usps .textblock-1 ul li {
			list-style-type:none;
			font-weight:bold;
			color:#6e7577;
			margin-bottom:10px;
			font-size:20px;
		}
			.block-usps .textblock-1 ul li span {
				float:left;
				margin-top:2px;
				margin-right:10px;
				width:25px;
				height:25px;
				background-image:url(../img/static/payoff-check.png);
				background-size:contain;
			}
			
			.block-usps .textblock-1 ul li::before {
				display:none !important;
			}

.block-usps .google-element {
	margin-top:40px;
	border-radius:10px;
}

@media (max-width: 1200px) {
	
	.block-usps .textblock-1 h3 {
		font-size:19px;
		line-height:19px;
	}
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.block-usps {
		padding-top:0;
	}
	
	.block-usps .textblock-1 ul li {
		font-size:16px;
		margin-left:40px;
	}

	.block-usps .textblock-1 ul li span {
		margin-left:-30px;
	}

	.block-usps .img-maxwidth {
		margin-top:20px;
		border-radius:10px;
	}

	
}