.page.content--artikelen .nomarge {
    margin-left: -15px;
    margin-right: -15px;
}

.page.content--artikelen .nmt {
    margin-top: 0;
}

.page.content--artikelen .artikel-item {
    float: left;
    width: 100%;
    padding: 20px;
    background-color: #e1e1e1;
    border: 1px solid #ccc;
    text-decoration: none;
}

.page.content--artikelen .artikel-item p {
    color: #000;
}

.page.content--artikelen .artikel-item .left {
    float: left;
    width: 70%;
}

.page.content--artikelen .artikel-item .right {
    float: left;
    text-align: center;
    margin-left: 10px;
    width: calc(30% - 20px);
}

.page.content--artikelen .artikel-item .right .image {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #000;
}

.page.content--artikelen .artikel-item .right .image img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 98%;
    height: 98%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    margin: 0;
}



@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {

    .page.content--artikelen .artikel-item .left {
        width: 100%;
    }

    .page.content--artikelen .artikel-item .right {
        width: 100%;
        margin-top: 20px;
    }

    .page.content--artikelen .artikel-item .right .image img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        height: 90%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        margin: 0;
    }

}