body {
	font-display: swap;
	font-family: 'Roboto', arial;
	background-color: #fff;
	color: #000;
	overflow: auto;
}

/**
Anti-aliasing ON
**/
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/**
Selection
**/
::selection {
	background: #000;
	color: #fff;
}

/*
CookieBot
CookieBot
CookieBot
CookieBot
*/
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
	display: none !important;
}

#CookiebotWidget {
	display: none !important;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
	display: none !important;
}

.CookiebotWidget-main-logo {
	display: none !important;
}

.sl-overlay {
	background-color: #fff !important;
	opacity: 0.98 !important;
}

/**
Marges
**/
.page-marge {
	margin-top: 180px;
}

.rest {
	clear: both;
	float: left;
	width: 100%;
}

/* GLOBAL ITEMS */
/* GLOBAL ITEMS */
/* GLOBAL ITEMS */
/* GLOBAL ITEMS */

.payoff {
	display: inline-block;
}

.payoff span {
	float: left;
	width: 40px;
	height: 40px;
}

.payoff span svg {
	fill: #23a638;
}

.payoff h1 {
	display: none;
}

.payoff h2 {
	float: left;
	width: 300px;
	font-family: 'Brown', arial;
	font-weight: bold;
	font-size: 25px;
	color: #23a638;
	margin: 0;
	padding: 0;
	margin-top: -10px;
}

.quote {
	display: inline-block;
	width: 100%;
	position: relative;
}

.quote .text {
	position: absolute;
	left: 0;
	width: 240px;
	font-size: 14px !important;
	line-height: 20px !important;
	color: #000;
	text-align: left;
}

.quote .text .p {
	border-radius: 10px;
	font-size: 14px !important;
	line-height: 20px !important;
	background-color: #aecc53;
	padding: 20px;
}

.quote .text span {
	position: absolute;
	right: -10px;
	top: 50px;
	width: 20px;
	height: 20px;
	background-color: #aecc53;
	transform: rotate(-45deg);
}

.quote .text a {
	color: #000;
}

.quote .google {
	float: left;
	width: 100%;
	margin-top: 20px;
}

.quote .google .rate {
	float: left;
	width: 40px;
	text-align: center;
}

.quote .google .rate .outer {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #dae2e3
}

.quote .google .rate .inner {
	position: absolute;
	top: 10px;
	width: 100%;
	text-align: center;
	font-size: 20px;
	color: #000;
	font-weight: bold;
}

.quote .google .stars {
	float: left;
	margin-top: 10px;
	margin-left: 10px;
	width: 100px;
	height: 20px;
	background-image: url(../img/static/google-stars.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.quote .google .reviews-count {
	float: left;
	margin-top: 14px;
	font-size: 15px;
	width: 100px;
	text-align: center;
	color: #6b707a;
}

.quote .google .logo-google {
	float: left;
	margin-top: 13px;
	width: 60px;
	height: 20px;
	background-image: url(../img/static/google-logo.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}


.team-slideshow {
	float: left;
	width: 100%;
	overflow: hidden;
}

.quote .person {
	position: absolute;
	right: 0;
	width: 170px;
	text-align: center;
}

.quote .person .image {
	display: inline-block;
	position: relative;
	height: 160px;
	width: 160px;
}

.quote .person .image .avatar {
	position: absolute;
	top: -70px;
	left: 50%;
	margin-left: -84px;
	height: 233px;
	width: 175px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 10;
}

.quote .person .image .avatar.charlie {
	background-image: url(../img/static/team/1_rood-01.svg);
}

.quote .person .image .avatar.maarten {
	background-image: url(../img/static/team/2-01.svg);
}

.quote .person .image .avatar-1 {
	background-image: url(../img/static/team/1_blauw-01.svg);
}

.quote .person .image .avatar-2 {
	background-image: url(../img/static/team/2-01.svg);
}

.quote .person .image .avatar-3 {
	background-image: url(../img/static/team/3-01.svg);
}

.quote .person .image .avatar-4 {
	background-image: url(../img/static/team/4-01.svg);
}

.quote .person .image .avatar-5 {
	background-image: url(../img/static/team/5-01.svg);
}

.quote .person .image .avatar-6 {
	background-image: url(../img/static/team/6-01.svg);
}

.quote .person .image .avatar-7 {
	background-image: url(../img/static/team/7-01.svg);
}

.quote .person .image .avatar-8 {
	background-image: url(../img/static/team/8-01.svg);
}

.quote .person .image .avatar-9 {
	background-image: url(../img/static/team/9-01.svg);
}

.quote .person .image .avatar-10 {
	background-image: url(../img/static/team/10-01.svg);
}

.quote .person .image .avatar-11 {
	background-image: url(../img/static/team/11-01.svg);
}

.quote .person .image .avatar-12 {
	background-image: url(../img/static/team/12-01.svg);
}

.quote .person .image .avatar-13 {
	background-image: url(../img/static/team/13.svg);
}

.quote .person .image .avatar-14 {
	background-image: url(../img/static/team/14.svg);
}

.quote .person .image .avatar-15 {
	background-image: url(../img/static/team/15.svg);
}

.quote .person .image .avatar-16 {
	background-image: url(../img/static/team/16.svg);
}

.quote .person .image .avatar-17 {
	background-image: url(../img/static/team/17.svg);
}

.quote .person .image .avatar-18 {
	background-image: url(../img/static/team/18.svg);
}

.quote .person .image .avatar-20 {
	background-image: url(../img/static/team/20.svg);
}

.quote .person .image .avatar-21 {
	background-image: url(../img/static/team/21.svg);
}

.quote .person .image .avatar-22 {
	background-image: url(../img/static/team/22.svg);
}

.quote .person .image .avatar-23 {
	background-image: url(../img/static/team/23.svg);
}

.quote .person .image .avatar-24 {
	background-image: url(../img/static/team/24.svg);
}

.quote .person .image .avatar-25 {
	background-image: url(../img/static/team/25.svg);
}

.quote .person .image .avatar-26 {
	background-image: url(../img/static/team/26.svg);
}
.quote .person .image .circle {
	/*
				position:absolute;
				bottom:0;
				width:130px;
				height:130px;
				left:50%;
				margin-left:-65px;
				border-radius:50%;
				background-color:#aecc53;
				z-index:5;
				*/
}

.quote .person .name {
	clear: both;
	float: left;
	width: 100%;
	font-family: 'Brown', arial;
	margin-top: 0px;
	margin-bottom: 5px;
	text-align: center;
	color: #000;
	font-size: 19px;
	font-weight: 700;
	line-height: 19px;
}

.quote .person .function {
	clear: both;
	float: left;
	width: 100%;
	margin-top: 2px;
	color: #000;
	font-size: 13px;
	font-weight: normal;
}

.quote .person .contact {
	clear: both;
	float: left;
	width: 100%;
	margin-top: 5px;
	text-align: center;
}

.quote .person .contact a {
	display: inline-block;
	padding: 8px;
	margin: 2px;
	font-size: 14px !important;
	line-height: 20px !important;
	color: #000;
	text-decoration: none;
	background-color: #e1e1e1;
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.quote .person .contact a:hover {
	text-decoration: underline;
}

.google-element {
	float: left;
	width: 100%;
	padding: 20px;
	background-color: #e7edee;
	text-align: center;
}

.google-element p {
	display: inline-block;
	width: 100%;
	color: #000;
	font-weight: bold;
	padding: 0;
	margin: 0;
}

.google-element .google-element-rest {
	display: inline-block;
	width: 300px;
}

.google-element .google-element-rest .left {
	float: left;
	margin-top: 10px;
	width: 50%;
}

.google-element .google-element-rest .rate {
	float: left;
	width: 100%;
	text-align: center;
}

.google-element .google-element-rest .rate .outer {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: #dae2e3
}

.google-element .google-element-rest .rate .inner {
	position: absolute;
	top: 15px;
	width: 100%;
	text-align: center;
	font-size: 40px;
	color: #000;
	font-weight: bold;
}

.google-element .google-element-rest .stars {
	clear: both;
	float: left;
	margin-top: 0px;
	width: 100%;
	height: 20px;
	background-image: url(../img/static/google-stars.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.google-element .google-element-rest .right {
	float: left;
	margin-top: 10px;
	width: 50%;
}

.google-element .google-element-rest .right .reviews-count {
	float: left;
	margin-top: 30px;
	font-size: 15px;
	width: 100%;
	text-align: center;
	color: #6b707a;
}

.google-element .google-element-rest .right .logo-google {
	float: left;
	margin-top: 30px;
	width: 100%;
	height: 30px;
	background-image: url(../img/static/google-logo.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {}


@media (max-width: 992px) {

	.quote {
		width: 66%;
	}

	.quote .text {
		position: inherit;
		width: 60%;
	}



}

@media (max-width: 768px) {

	.payoff h2 {
		margin-top: 0px;
	}

	.button-wrapper {
		float: left;
		width: 100%;
		text-align: center;
	}

	.quote {
		width: 85%;
	}

	.quote .text span {
		display: none;
	}

	.quote .person {
		position: inherit;
		bottom: 0;
		margin-top: 20px;
		width: 300px !important;
		left: 50%;
		margin-left: -150px;
	}

	.quote .person .image {
		width: 130px !important;
		height: 140px !important;
		margin-top: 0;
	}

	.quote .person .image .avatar {
		top: -10px;
		left: 50%;
		margin-left: -66px;
		height: 158px;
		width: 125px;
	}

	.quote .person .name {
		font-size: 16px;
		margin-top: 20px;
	}

	.quote .text {
		width: 100%;
		font-size: 16px;
		line-height: 23px;
	}

	.google-element .google-element-rest {
		width: 100%;
	}

	.quote .google .logo-google {
		width: 30px;
		height: 23px;
	}

}