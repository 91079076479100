.popup--av {
	float:left;
	width:800px;
}

	.popup--av .content {
		float:left;
		width:100%;
		background-color:#fff;
		padding:20px;
		padding-bottom:30px;
		border-radius:4px;
		text-align:left;
	}
	
		.popup--av .content h3 {
			margin-top:0;
		}
		
		.popup--av .content p {
			color:#000;
		}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
	.popup--av {
		float:left;
		width:800px;
	}

	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.popup--av {
		float:left;
		width:250px;
	}
	
		.popup--av .content {
			padding:10px;
			padding-top:20px;
			padding-bottom:20px;
		}
		
	
}