.page.content--binnenpagina.content--recentopgeleverd {
}

	.page.content--binnenpagina.content--recentopgeleverd .project {
		float:left;
		width:100%;
		padding:20px;
		margin-bottom:20px;
		background-color:#e7edee;
		border-radius:20px;
		color:#000;
	}
	
		.page.content--binnenpagina.content--recentopgeleverd .project h2 {
			float:left;
			width:100%;
			padding:0;
			margin:0;
			font-size:24px;
			color:#000;
		}
		
		.page.content--binnenpagina.content--recentopgeleverd .project h3 {
			float:left;
			width:100%;
			padding:0;
			margin:0;
			font-size:20px;
			color:#888;
			font-weight:normal;
		}
		
		.page.content--binnenpagina.content--recentopgeleverd .project img {
			float:left;
			width:100%;
			margin-top:10px;
			padding:10px;
			background-color:#fff;
		}
		
@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
	.block-recentopgeleverd .slider .icon-prev {
		display:none;
	}
	.block-recentopgeleverd .slider .icon-next {
		display:none;
	}
	
}

@media (max-width: 768px) {
	
}