/*
Buttons
Buttons
Buttons
Buttons
*/
.button {
	cursor:pointer;
}
	
.button-1 {
	float:left;
	cursor:pointer;
	color:#fff;
	background-color:#000;
	padding:10px;
	padding-left:20px;
	padding-right:10px;
	background-clip: padding-box; 
	border-radius:4px;
	transition:all 0.3s;
}	

	.button-1.inline {
		display:inline-block;
		float:none;
	}

	.button-1:hover {
		color:#fff;
		border-bottom:3px solid rgba(0,0,0,0.5);
		transform:scale(1.05);
	}
	.button-1:hover > .label {
		color:#fff;
		text-decoration:underline;
	}

	.button-1 .icon {
		float:left;
		margin-top:8px;
		margin-left:20px;
		padding-right:5px;
		padding-bottom:2px;
		font-size:15px;
		line-height:15px;
		color:#fff;
	}
		.button-1 .icon.small {
			margin-top:5px;
			font-size:17px;
		}
	
	.button-1 .inline {
		display:inline-block;
	}
	
	.button-1.no-icon {
		padding-left:20px;		
		padding-right:20px;
	}
	
	.button-1 .label {
		font-family:'Open Sans',arial;
		float:left;
		margin-top:1px;
		font-weight:700;
		font-size:14px;
		line-height:23px;
		color:#fff;
		letter-spacing:0px;
	}
	
	.button-1.cta {
		background-color:#23a638;
		box-shadow:0 0 10px rgba(0,0,0,0.4);
	}
	
		.button-1.cta .icon {
			font-size:30px;
			margin-top:10px;
		}
	
	.button-1.noshadow {
		border-bottom:0;
	}

.button-3 {
	display:inline-block;
	margin:5px;
	font-size:15px;
	cursor:pointer;
	color:#000;
	background-color:#e1e1e1;
	padding:8px 10px;
	background-clip: padding-box; 
	border-radius:4px;
	transition:all 0.3s;
	text-decoration:none;
}

	.button-3 span.label {
		float:left;
		margin-top:2px;
	}
	.button-3 span.icon-1 {
		float:left;
		margin-top:2px;
		margin-right:10px;
	}
	.button-3 span.icon-2 {
		float:left;
		margin-top:4px;
		margin-right:10px;
	}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
	.button-1 {
		padding-left:10px;
	}
	
	.button-1 .label {
		font-size:12px;
	}
	
	.button-1 .icon {
		margin-left:80px;
	}
	
	
	
}

@media (max-width: 768px) {

	.button-1 {
		padding-left:10px;
	}

	.button-1 .label {
		width:100%;
		font-size:14px;
		line-height:20px;
		padding-left:0;
		text-align:center;
	}
	
	.button-1 .icon {
		margin-left:45%;
	}
	
	.button-3 {
		font-size:13px;
	}

	
}
