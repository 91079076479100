/* NOT FOUND */
/* NOT FOUND */
/* NOT FOUND */
/* NOT FOUND */

.page.content--deuren {
	float:left;
	width:100%;
	text-align:center;
	color:#000;
}

	.page.content--deuren .logo {
		display:inline-block;
		width:200px;
		height:110px;
		background-image:url(../img/static/logo-gonxxt.svg);
		background-position:center;
		background-repeat:no-repeat;
		background-size:contain;
}

	.page.content--deuren .door {
		float:left;
		width:100%;
		margin-bottom:40px;
	}
	
		.page.content--deuren .door .image {
			float:left;
			width:100%;
			height:600px;
			background-size:contain;
			background-repeat:no-repeat;
			background-position:center;
		}
		
		.page.content--deuren .door .name {
			clear:both;
			float:left;
			width:100%;
			font-size:30px;
			font-weight:bold;
			color:#000;
			margin-top:20px;
		}
	
	.page.content--deuren nav {
		float:left;
		width:100%;
		text-align:center;
	}
	
	.page.content--deuren nav a {
		display:inline-block;
		font-family:'Brown',arial;
		padding:10px 15px; 
		background-color:#000;
		color:#fff;
		text-decoration:none;
		text-transform:uppercase;
		font-weight:bold;
	}
		.page.content--deuren nav a:hover,
		.page.content--deuren nav a.active {
			background-color:#23a638;
			color:#fff;
			text-decoration:underline;
		}

		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
  
}