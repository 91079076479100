.block-klantervaringen {
	float:left;
	width:100%;
	padding-top:40px;
	padding-bottom:40px;
	min-height:400px;
	background-color:#1eafe2;
	background-image:url(../img/static/block-klantervaringen-bckgrnd.jpg);
	background-repeat:no-repeat;
	background-position:center;
	background-size:cover;
}

		.block-klantervaringen .textblock-1 h2 {
			font-family:'Brown',arial;
			display:inline-block;
			font-size:40px;
			line-height:40px;
			font-weight:bold;
			padding:0;
			margin:0;
			margin-top:20px;
			margin-bottom:10px;
			padding:10px 20px;
			color:#fff;
		}

	.block-klantervaringen .slider {
		margin-top:30px;
		margin-bottom:30px;
	}
	
		.block-klantervaringen .swiper-container {
			width:100%;
		}
	
		.block-klantervaringen .slider .icon-prev {
			position:absolute;
			left:-60px;
			top:50%;
			margin-top:-20px;
			color:#fff;
			cursor:pointer;
		}
		
		.block-klantervaringen .slider .icon-next {
			position:absolute;
			right:-60px;
			top:50%;
			margin-top:-20px;
			color:#fff;
			cursor:pointer;
		}
		
			.block-klantervaringen .slider .icon-prev span,
			.block-klantervaringen .slider .icon-next span {
				font-size:30px;
				color:#fff;
			}

		.block-klantervaringen .slider .item {
			float:left;
			color:#000;
			width:100%;
			height:300px;
			background-color:#fff;
			box-sizing:border-box;
		}
		
			.block-klantervaringen .slider .item .inside {
				float:left;
				width:100%;
				padding:20px;
			}
			
				.block-klantervaringen .slider .item .inside .top {
					float:left;
					width:100%;
					font-size:18px;
					margin-bottom:20px;
					font-weight:bold;
				}
				
				.block-klantervaringen .slider .item .inside .top .title {
					background-color:#fff;
					background-image:none;
					font-family:'Brown',arial;
				}
				
				.block-klantervaringen .slider .item .inside .bottom {
					float:left;
					width:100%;
				}
				
					.block-klantervaringen .slider .item .inside .bottom .left {
						float:left;
						width:280px;
					}
					
						.block-klantervaringen .slider .item .inside .bottom .left .textblock-1 p {
							height:158px;
							overflow:hidden;
							color:#000;
							font-size:15px;
							line-height:22px;
						}
						
						.block-klantervaringen .slider .item .inside .bottom .left .rest {
							float:left;
							width:100%;
							margin-top:5px;
						}
							
							.block-klantervaringen .slider .item .inside .bottom .left .rest .stars {
								float:left;
								margin-top:20px;
								width:100px;
								height:20px;
								background-size:contain;
								background-repeat:no-repeat;
							}
							.block-klantervaringen .slider .item .inside .bottom .left .rest .stars.stars-5 {
								background-image:url(../img/static/google-stars.png);
							}
							
							.block-klantervaringen .slider .item .inside .bottom .left .rest .name {
								float:right;
								width:150px;
								font-size:13px;
								line-height:17px;
								margin-top:5px;
							}
							.block-klantervaringen .slider .item .inside .bottom .left .rest .date {
								clear:both;
								float:left;
								width:100%;
								font-size:14px;
								margin-top:5px;
							}
					
					.block-klantervaringen .slider .item .inside .bottom .right {
						float:left;
						width:200px;
						margin-left:20px;
					}
					
						.block-klantervaringen .slider .item .inside .bottom .right .image {
							float:left;
							width:100%;
							height:215px;
							background-color:#e1e1e1;
							background-size:contain;
							background-position:center;
							background-repeat:no-repeat;												
						}
				

@media (max-width: 1200px) {
	
	.block-klantervaringen .slider .item .inside .bottom .left {
		width:190px;
	}
	
	.block-klantervaringen .slider .item .inside .bottom .left .textblock-1 p {
		font-size: 13px;
		line-height: 20px;
	}
	
	.block-klantervaringen .slider .item {
		height:340px;
	}
	
}

@media (max-width: 1005px) {

	.block-klantervaringen .slider .item {
		text-align:center;
	}

	.block-klantervaringen .slider .item .inside .bottom {
		float:none;
		display:inline-block;
	}

	.block-klantervaringen .slider .item .inside .bottom .left {
		width:60%;
	}
	.block-klantervaringen .slider .item .inside .bottom .right {
		width:30%;
	}
	
}

@media (max-width: 768px) {
	
	.block-klantervaringen {
		padding-top:0;
	}

	.block-klantervaringen .slider .item {
		text-align:left;
	}
	
	.block-klantervaringen .slider .item .inside .bottom .left .textblock-1 p {
		height:auto;
	}
	
	.block-klantervaringen .slider .item .inside .bottom .right .image {
		margin-top:20px;
	}

	.block-klantervaringen .textblock-1 h2 {
		font-size:18px;
		line-height:20px;
	}
	
}