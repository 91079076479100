.block-usps-seo {
	float:left;
	width:100%;
	padding-top:40px;
	padding-bottom:0px;
	background-color:#fff;
}

	.block-usps-seo .textblock-1 p {
		color:#888;
	}
	
		.block-usps-seo .textblock-1 h3 {
			color:#000;
			font-size:25px;
		}
	
		.block-usps-seo .textblock-1 ul li {
			list-style-type:none;
			font-weight:bold;
			color:#6e7577;
			margin-bottom:10px;
			font-size:20px;
		}
			.block-usps-seo .textblock-1 ul li span {
				float:left;
				margin-top:2px;
				margin-right:10px;
				width:25px;
				height:25px;
				background-image:url(../img/static/payoff-check.png);
				background-size:contain;
			}
			
			.block-usps-seo .textblock-1 ul li::before {
				display:none !important;
			}

.block-usps-seo .google-element {
	margin-top:40px;
}

@media (max-width: 1200px) {
	
	.block-usps-seo .textblock-1 h3 {
		font-size:19px;
		line-height:19px;
	}
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.block-usps-seo {
		padding-top:0;
	}
	
	.block-usps-seo .textblock-1 ul li {
		font-size:12px;
	}
	
}