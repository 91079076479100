
/* 
Large tablets and desktops
*/
$screen-xl-max: 1200px;

/* 
Tablets and small desktops
*/
$screen-lg-max: 992px;

/* 
Small tablets (portrait view)
*/
$screen-md-max: 768px;

/* 
Small tablets and large smartphones (landscape view)
*/
$screen-sm-max: 576px;

/* 
Extra large devices
*/
@mixin xl {
   @media (max-width: #{$screen-xl-max}) {
       @content;
   }
}

/* 
Large devices
*/
@mixin lg {
   @media (max-width: #{$screen-lg-max}) {
       @content;
   }
}

/*
Medium devices
*/
@mixin md {
   @media (max-width: #{$screen-md-max}) {
       @content;
   }
}

/* 
Small devices
*/
@mixin sm {
   @media (max-width: #{$screen-sm-max}) {
       @content;
   }
}