.block-vakman {
	float:left;
	width:100%;
	padding-top:40px;
	padding-bottom:40px;
	background-color:#fff;
}

	.block-vakman .greyblock {
		float:left;
		width:100%;
		background-color:#e1e1e1;
		padding: 30px;
		border-radius:20px;
	}

	.block-vakman .greyblock h2 {
		float:left;
		width:100%;
		font-size:30px !important;
		padding:0 !important;
		margin:0 !important;
		margin-bottom:20px !important;
	}

	.block-vakman .greyblock p {
		color:#000;
	}

	.block-vakman .image {
		float:left;
		width:100%;
		height:100%;
		border-radius:20px;
		background-image:url('../img/static/vakman.jpg');
		background-size:contain;
		background-position: center;
		background-repeat:no-repeat;
	}

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {

	.block-vakman {
		padding-top:20px;
		padding-bottom:20px;
	}
	
		.block-vakman .greyblock {
			padding: 20px;
		}

			.block-vakman .greyblock h2 {
				font-size:20px !important;
				line-height:25px !important;
				margin-bottom:10px !important;
			}

			.block-vakman .image-small { 
				float:right;
				width:80px;
				height:80px;
				border-radius:10px;
				margin-left:10px;
				background-image:url('../img/static/vakman.jpg');
				background-size:contain;
				background-repeat:no-repeat;
			}

			.block-vakman .image { 
				display:none;
			}

}