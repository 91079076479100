.page.content--binnenpagina.vacature {
	float:left;
	width:100%;
}

	.page.content--binnenpagina.vacature .click-feedback {
		float:left;
		width:100%;
	}

	.page.content--binnenpagina.vacature .feedback .textblock-1 h2 {
		float:left;
		width:100%;
		margin-bottom:10px;
	}
	.page.content--binnenpagina.vacature .feedback .textblock-1 p {
		float:left;
		width:100%;
		margin-bottom:10px;
	}

	.page.content--binnenpagina.vacature .bt {
		clear:both;
		float:left;
		width:100%;
		background-color:#e1e1e1;
		padding:15px;
		padding-left:20px;
		padding-right:20px;
		margin-bottom:10px;
		border-radius:20px;
		font-size:20px;
		color:#000;
		cursor:pointer;
	}

	.page.content--binnenpagina.vacature .bt.interesse {
		background-color:#23a638;
	}	

	.page.content--binnenpagina.vacature .bt.interesse .label {
		color:#fff;
	}

	.page.content--binnenpagina.vacature .bt .loading {
		float:left;
		width:20px;
		height:20px;
		margin-right:10px;
		border-radius:50%;
		background-image:url('../img/static/assets/loading.svg');
		background-size:contain;
		background-repeat:no-repeat
	}

	.page.content--binnenpagina.vacature .bt .loading {
		float:left;
		margin-left:10px;
	}

	.page.content--binnenpagina.vacature .bt a {
		color:#000;
		text-decoration:none;
	}

	.page.content--binnenpagina.vacature .bt:hover {
		background-color:#ccc;
	}
		
@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {

	.page.content--binnenpagina.vacature .bt {
		padding:10px;
		padding-left:15px;
		padding-right:15px;
		font-size:15px;
	}
	
	
}