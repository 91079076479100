.page.content--producten {
}

	.block-producten {
		float:left;
		width:100%;
		margin-top:40px;
		padding-bottom:40px;
		background-color:#fff;
	}
	
	
		
@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.block-producten {
		margin-top:10px;
	}
	
}