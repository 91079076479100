.page.content--home {
}

	.page.content--home .content {
		float:left;
		width:100%;
		background-color:#fff;
	}
	
		.page.content--home .content .spotlight {
			float:left;
			position:relative;
			width:100%;
			height:calc(100vh - 115px);
			min-height:550px;
			background-image:url(../img/static/header-title.webp);
			background-size:cover;
			background-position:center;
		}
		
			.page.content--home .content .spotlight .spotlight-inside {
				position: absolute;
				width:1120px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		
			.page.content--home .content .spotlight .spotlight-inside .products {
				float:left;
				width:600px;
				height:500px;
				border-radius:20px;
				background-color:#fff;
				box-shadow:0 0 20px rgba(0,0,0,0.2);
			}

				.page.content--home .content .spotlight .spotlight-inside .products .row {
					clear:both;
					float:left;
					width:100%;
					margin-left:15px;
					margin-top:15px;
				}
				
					.page.content--home .content .spotlight .spotlight-inside .products .row .product-item {
						float:left;
						width:170px;
						margin:10px;
					}
					
						.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon {
							float:left;
							width:100%;
							height:180px;
							background-color:#e7edee;
							border-radius:20px;
							background-repeat:no-repeat;
							background-position:center;
							background-size:80%;
						}
						
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.kozijnen {
								background-image:url(../img/static/icon-kozijnen-dicht.png);
							}
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.kozijnen:hover {
								background-image:url(../img/static/icon-kozijnen-open.png);
							}
							
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.deuren {
								background-image:url(../img/static/icon-voordeur-dicht.png);
							}
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.deuren:hover {
								background-image:url(../img/static/icon-voordeur-open.png);
							}
							
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.tuindeuren {
								background-image:url(../img/static/icon-tuindeuren-dicht.jpg);
							}
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.tuindeuren:hover {
								background-image:url(../img/static/icon-tuindeuren-open.jpg);
							}
							
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.schuifpuien {
								background-image:url(../img/static/icon-schuifpui-dicht.svg);
							}
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.schuifpuien:hover {
								background-image:url(../img/static/icon-schuifpui-open.svg);
							}
							
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.gevelbekleding {
								background-image:url(../img/static/icon-gevelbekleding-dicht.png);
								background-size:90%;
							}
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.gevelbekleding:hover {
								background-image:url(../img/static/icon-gevelbekleding-open.png);
							}
							
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.dakkapelrenovaties {
								background-image:url(../img/static/icon-dakkapel-dicht.png);
								background-size:90%;
							}
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon.dakkapelrenovaties:hover {
								background-image:url(../img/static/icon-dakkapel-open.png);
							}
						
						.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .mp4 {
							float:left;
							width:100%;
							height:180px;
							background-color:#e7edee;
							border-radius:20px;
							overflow:hidden;
						}
						
							.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .mp4 video {
								 width:100%;
								 height:100%;
								object-fit:contain;
							}
						
						.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .label {
							float:left;
							width:100%;
							margin-top:10px;
							text-align:center;
							font-size:17px;
							font-family:'Brown',arial;
							color:#000;
							font-weight:bold;
							text-decoration:none;
						}
						
			/*
			Spotlight: Intro
			*/
			.page.content--home .content .spotlight .spotlight-inside .intro {
				float:left;
				margin-top:30px;
				margin-left:50px;
				width:450px;
				height:400px;
				text-align:center;
			}
			
				.page.content--home .content .spotlight .spotlight-inside .intro .quote {
					margin-top:70px;
				}


				.page.content--home .textblock-1 h2 {
					font-family:'Brown',arial;
					display:inline-block;
					font-size:40px;
					line-height:40px;
					font-weight:bold;
					padding:0;
					margin:0;
					margin-top:20px;
					margin-bottom:10px;
					padding:10px 20px;
					color:#fff;
				}

@media (max-width: 1200px) {
	
	.page.content--home .content .spotlight {
		height:500px;
		min-height:500px;
	}
	
	.page.content--home .content .spotlight .spotlight-inside {
		width:960px;
	}
	
	.page.content--home .content .spotlight .spotlight-inside .products {
		width:440px;
		height:410px;
	}
	
	.page.content--home .content .spotlight .spotlight-inside .products .row .product-item {
		width:120px;
	}
	
	.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .icon {
		height:140px;
	}
	
	.page.content--home .content .spotlight .spotlight-inside .products .row .product-item .label {
		font-size:11px;
	}
	
}

@media (max-width: 992px) {
	
	.page.content--home .content .spotlight {
		height:960px;
	}
	
	.page.content--home .content .spotlight .spotlight-inside .products {
		width:100%;
	}
	
	.page.content--home .content .spotlight .spotlight-inside {
		width:670px;
	}
	
	.page.content--home .content .spotlight .spotlight-inside .products .row .product-item {
		width:29%;
	}
	
	.page.content--home .content .spotlight .spotlight-inside .intro {
		margin-left:0;
		width:100%;
	}
	
	.spotlight-inside .quote .person {
		top:0;
	}
	
}

@media (max-width: 768px) {
	
	
	.page.content--home .content .spotlight {
		position:relative;
		height:auto;
		min-height:auto;
		padding-bottom:40px;
	}
	
	.page.content--home .content .spotlight .spotlight-inside .intro {
		width:100%;
		margin-top:30px;
		height:inherit;
	}
	
		.page.content--home .content .spotlight .spotlight-inside .intro .quote {
			margin-top:20px;
		}
	
	.page.content--home .content .spotlight .spotlight-inside {
		position:inherit;
		width:100%;
		padding-bottom:80px;
		top:auto;
		left:auto;
		transform:inherit;
	}
	
	.page.content--home .content .spotlight .payoff {
		margin-top:0px;	
	}
	
	.page.content--home .content .spotlight .spotlight-inside .products {
		display:none;
	}
	
	.page.content--home .content .spotlight .spotlight-inside .products-mobile {
		display:block;
	}
	
	.payoff h1 {
		display:none;
	}
	.payoff h2 {
		width:200px;
		font-size:16px;
	}

	.page.content--home .textblock-1 h2 {
		font-size:18px;
		line-height:20px;
	}
	
}