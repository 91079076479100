.page.content--binnenpagina .nomarge {
	margin-left:-15px;
	margin-right:-15px;
}

.page.content--binnenpagina .nmt {
	margin-top:0;
}

.page.content--binnenpagina section {
	float:left;
	width:100%;
	padding:20px;
	background-color:#e1e1e1;
	margin-bottom:20px;
}

.page.content--binnenpagina section .image {
	float:left;
	width:100%;
	text-align:center;
}

.page.content--binnenpagina section .image img {
	display:inline-block;
}
		
@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
}