.block-recentopgeleverd {
	float:left;
	width:100%;
	padding-top:40px;
	padding-bottom:40px;
	min-height:400px;
	background-color:#23a638;
}

	.block-recentopgeleverd .slider {
		position:relative;
		margin-top:30px;
		margin-bottom:30px;
	}
	
		.block-recentopgeleverd .slider .swiper-container {
			width:100%;
		}
		
		.block-recentopgeleverd .slider .icon-prev {
			position:absolute;
			left:-60px;
			top:50%;
			margin-top:-20px;
			color:#fff;
			cursor:pointer;
		}
		
		.block-recentopgeleverd .slider .icon-next {
			position:absolute;
			right:-60px;
			top:50%;
			margin-top:-20px;
			color:#fff;
			cursor:pointer;
		}
		
			.block-recentopgeleverd .slider .icon-prev span,
			.block-recentopgeleverd .slider .icon-next span {
				font-size:30px;
				color:#fff;
			}
		
		.block-recentopgeleverd .slider .item {
			float:left;
			width:100%;
			background-color:#fff;
			padding:10px;
			box-sizing:border-box;
		}
		
		
			.block-recentopgeleverd .slider .item .image {
				float:left;
				width:100%;
				height:412px;
				background-size:contain;
				background-repeat:no-repeat;
			}
			

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.block-recentopgeleverd .slider .item .image {
		height:192px;
	}
	
}