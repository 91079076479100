/*
Version 0.101

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

/* MODAL ****************************************************************************************** */
/* MODAL ****************************************************************************************** */
/* MODAL ****************************************************************************************** */
/* MODAL ****************************************************************************************** */

.modal-background { 
	display:none;
	position:fixed;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.6);
	z-index:9000;
}

.modal-loader {
	display:none;
	position:fixed;
	left:-9999px;
	z-index:9500;
}

.modal .header {
	float:left;
	width:100%;
	padding:10px;
	background-color:#000;
}

	.modal .header h2 {
		float:left;
		font-family:'Raleway',arial;
		color:#fff;
		width:100%;
		text-align:left;
		font-size:20px;
		line-height:20px;
		margin-top:0;
		margin-bottom:0;
		padding-top:0;
		padding-bottom:0;
		text-transform:uppercase;
	}

	.modal .header .icon-close {
		float:right;
		width:30px;
		font-size:30px;
		line-height:0;
		color:#fff;
		cursor:pointer;
	}

.modal .content a {
	text-decoration:none;
}

.modal .content {
	clear:both;
	float:left;
	width:100%;
	color:#000;
	padding:20px;
}

.modal .actions {
	clear:both;
	float:left;
	width:100%;
}

	.modal .actions .button {
		margin-left:2px;
	}


/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {	
  
}