.block-menu {
	float:left;
	margin-left:5px;
	width:75%;
}

.block-menu .item {
	float:left;
	width:70px;
	height:60px;
	text-align:center;
/*	border-right:1px solid #fff;*/
}

	.block-menu .item .inside {
		float:left;
		margin:5px;
		width:60px;
		height:50px;
		background-color:rgba(255,255,255,0.3);
		border-radius:5px;
	}

	/*.block-menu .item .inside .icon {
		float:left;
		width:100%;
		height:70%;
	}*/

	.block-menu .item .inside .label {
		float:left;
		width:100%;
		height:100%;
		padding-left:5px;
		padding-right:5px;
		margin-top:9px;
		font-size:10px;
		font-weight:bold;
		line-height:14px;
		color:#000;
	}


.mobile-menu {
	display:block;
	position:fixed;
	left:0;
	top:-9999px;
	width:100%;
	height:100%;
	padding:10px;
	background-color:#fff;
	z-index:4000;
	box-shadow:0 0 10px 10px rgba(0,0,0,0.5);
}

.mobile-menu .scroller .button {
	display:inline-block;
}

.mobile-menu .scroller {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:auto;
	text-align:center;
}
	
	.mobile-menu .scroller .inside {
		float:left;
		width:100%;
		padding:0;	
		margin-top:20px;
	}
	
	.mobile-menu .scroller .inside .logo {
		display:inline-block;
		margin-top:40px;
		width:100%;
		height:90px;
		background-image:url(../img/static/logo-gonxxt.svg);
		background-position:center;
		background-size:contain;
		background-repeat:no-repeat;
	}
	
	.mobile-menu .scroller .inside .button.button-1 {
		float:none;
		margin-top:20px;
		border:1px solid #ccc;
		margin-top:20px;
	}
	
			
	.mobile-menu .scroller .inside nav {
		display:inline-block;
		margin-top:20px;
		width:100%;
		text-align:center;
	}
	
	.mobile-menu .scroller .inside nav ul {
		list-style-type:none;
		padding:0;
		margin:0;
	}
	
	.mobile-menu .scroller .inside nav ul li {
		list-style-type:none;
		padding:0;
		margin:0;
	}
	
	.mobile-menu .scroller .inside nav ul li a {
		display:inline-block;
		text-align:center;
		font-family:'Brown',arial;
		font-size:18px;
		line-height:20px;
		margin-bottom:10px;
		color:#000;
		text-decoration:none;
		border-bottom:2px solid #fff;
	}
	
	.mobile-menu .scroller .inside nav a:hover,
	.mobile-menu .scroller .inside nav a.active {
		font-weight:bold;
		border-bottom:2px solid #e1e1e1;
	}
	
	.mobile-menu .scroller .inside .extramarge {
		float:left;
		width:100%;
		margin-top:20px;
		text-align:center;
	}
	
	
	.mobile-menu .scroller .inside .contact {
		float:left;
		width:100%;		
		margin-top:30px;
		text-align:center;
		padding-bottom:30px;
	}
	
		.mobile-menu .scroller .inside .contact a {
			display:inline-block;
			font-family:'Open Sans',arial;
			font-size:18px;
			line-height:25px;
			color:#000;
			text-decoration:none;
		}

.mobile-menu .menu-button {
	float:right;
	margin-top:20px;
	margin-right:25px;
	position:relative;
	width:30px;
	height:29px;
	cursor:pointer;
	border-radius:2px;
	background-color:#e1e1e1;
	border-radius:4px;
	}
	
	.mobile-menu .menu-button .b-row {
		position:absolute;
		width:25px;
		height:2px;
		background-color:#fff;
	}
	
		.mobile-menu .menu-button .b-row.row-1 {
			left:5px;
			top:5px;
		}
		.mobile-menu .menu-button .b-row.row-2 {
			left:5px;
			top:12px;
		}
		.mobile-menu .menu-button .b-row.row-3 {
			left:5px;
			top:19px;
		}
	
	.mobile-menu .menu-button.exit {
		position:absolute;
		top:0;
		right:0;
		z-index:100;
	}
		
		.mobile-menu .menu-button.exit .b-row.row-1 {
			left:3px;
			top:13px;
			transform:rotate(45deg);
			background-color:#000;
		}
		.mobile-menu .menu-button.exit .b-row.row-3 {
			left:3px;
			top:13px;
			transform:rotate(-45deg);
			background-color:#000;
		}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.mobile-menu .scroller .inside .contact a {
		line-height:32px;
	}
		
}