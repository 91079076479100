.block-ditzijnwij {
	float:left;
	width:100%;
	padding-top:40px;
	padding-bottom:40px;
	min-height:400px;
	background-color:#fff;
}

	.block-ditzijnwij .container-fluid {
		padding-left:80px;
		padding-right:80px;
	}

	.block-ditzijnwij .textblock-1 p {
		color:#888;
	}

	.block-ditzijnwij .textblock-1 p a {
		color:#888;
	}

	.block-ditzijnwij .textblock-1 h2 {
		font-family:'Brown',arial;
		display:inline-block;
		font-size:40px;
		line-height:40px;
		font-weight:bold;
		padding:0;
		margin:0;
		margin-top:20px;
		margin-bottom:10px;
		padding:10px 20px;
		color:#fff;
	}

.block-ditzijnwij .quote {
	display:inline-block;
	margin-bottom:20px;
	width:250px;
}

	.block-ditzijnwij .quote .person {
		width:auto;
	}


	.block-ditzijnwij .quote .person .image {
	  display:inline-block;
	  position:relative;
	  height:250px;
	  width:250px;
  }
  
	 .block-ditzijnwij .quote .person .image .avatar {
		 display:block;
		  position:inherit;
		  top:auto;
		  left:auto;
		  margin-left:auto;
		  height:250px;
		  width:250px;
		  background-size:contain;
		  background-repeat:no-repeat;
		  background-position:center;
		  z-index:10;
	  }
		
	.block-ditzijnwij .quote .person {
		position:inherit !important;
	}	
	
		.block-ditzijnwij .quote .person .function {
			margin-top:5px;
			color:#23a638;
			font-size:14px;
			font-weight:bold;
		}
		.block-ditzijnwij .quote .person .intro {
			font-size:13px;
			line-height:20px;
			color:#000;
		}

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {

	.block-ditzijnwij .container-fluid {
		padding-left:20px;
		padding-right:20px;
	}
	
	.block-ditzijnwij {
		padding-top:0;
	}
	
	.block-ditzijnwij .mt-60 {
		margin-top:10px;
	}

	.block-ditzijnwij .col-md-2 {
		padding:0;
	}
	
	.block-ditzijnwij .quote {
		width:100%;
	}
	
		.block-ditzijnwij .quote .person {
			width:100% !important;
		}
	
	
	.block-ditzijnwij .quote .person .image {
	  height:150px;
	  width:150px;
	  		  margin-top:0;
  }
  	
	.block-ditzijnwij  .person .name {
		font-size:17px;
	}
	
	.block-ditzijnwij .quote .person .function {
		font-size:12px;
	}
  
	 .block-ditzijnwij .quote .person .image .avatar {
		  height:130px;
		  width:130px;
		  margin-left:0;
	  }
	
	.block-ditzijnwij .quote .person {
		margin-top:auto !important;
		width:50% auto!important;
		left:auto !important;
		margin-left:0 !important;
	}
	
		.block-ditzijnwij .quote .person .intro {
			display:none;
		}
	
	.block-ditzijnwij .textblock-1 h2 {
		font-size:18px;
		line-height:20px;
	}

}