.popup--slideshow {
	float:left;
	width:1200px;
}

	.popup--slideshow .content {
		float:left;
		width:100%;
		background-color:#fff;
		padding:20px;
		border-radius:4px;
		text-align:left;
		color:#000;
	}
	
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
	.popup--slideshow {
		float:left;
		width:800px;
	}
	
	
}

@media (max-width: 992px) {
	
	.popup--slideshow {
		float:left;
		width:700px;
	}
	
}

@media (max-width: 768px) {
	
	.popup--slideshow {
		width:300px;
	}
	
}