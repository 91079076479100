/**
Fonts
Fonts
Fonts
Fonts
**/
.font-opensans {
	font-family:'Open Sans',arial;
}

/*
Titles
Titles
Titles
Titles
*/
.title-1 {
	font-family:'Gotham Black',arial;
	font-size:50px;
	padding:0;
	margin:0;
	line-height:55px;
	color:#fff;
	text-transform:uppercase;
}

/**
Textblocks
Textblocks
Textblocks
Textblocks
**/
.textblock-1 {
	float:left;
	position:relative;
	width:100%;
	font-family:'Open Sans',arial;
	color:#000;
}

	.textblock-1::selection {
		background:#000;
		color:#fff;
	}
	
	.textblock-1 a {
		color:#000;
		text-decoration:underline;
	}
	
	.textblock-1 p,
	.textblock-1 .p {
		font-size:16px;
		line-height:28px;
		font-weight:normal;
		margin:0;
		padding:0;
		color:#fff;
	}

	.textblock-1 h1 {
		font-family:'Brown',arial;
		font-size:20px;
		line-height:28px;
		font-weight:bold;
		padding:0;
		margin:0;
		padding-top:0;
		padding-bottom:0;
		color:#ff6400;
	}
	
	.textblock-1 h2 {
		font-family:'Brown',arial;
		display:inline-block;
		font-size:25px;
		line-height:30px;
		margin: 0;
		font-weight:bold;
		color:#000;

		/*font-family:'Brown',arial;
		display:inline-block;
		font-size:40px;
		line-height:40px;
		font-weight:bold;
		padding:0;
		margin:0;
		margin-top:20px;
		margin-bottom:10px;
		padding:10px 20px;
		color:#fff;*/
	}
	
	.textblock-1 h3 {
		font-family:'Brown',arial;
		display:inline-block;
		font-size:25px;
		line-height:30px;
		margin: 0;
		font-weight:bold;
		color:#000;
	}
	
	.textblock-1 ul {
		margin:0;
		font-size:17px;
		line-height:29px;
		list-style-position:inside;
	}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
	.textblock-1 p,
	.textblock-1 .p {
		font-size:14px;
		line-height:25px;
	}
	
	.textblock-1 ul {
		padding:0;
		font-size: 14px;
		line-height: 25px;
	}
	
}

@media (max-width: 992px) {
	
	.textblock-1 p,
	.textblock-1 .p {
		font-size:15px;
		line-height:25px;
	}
	
	.textblock-1 ul {
		font-size:15px;
		line-height:25px;
	}
	
	.textblock-2 p {
		font-size:15px;
		line-height:25px;
	}

	.textblock-1 h1 {
		font-size:20px;
		line-height:23px;
	}
	
	
}

@media (max-width: 768px) {

.title-1 {
	font-size:20px;
	line-height:24px;
	margin-bottom:10px;
	margin-top:10px;
}
.title-1.big {
	margin-bottom:10px;
	margin-top:10px;
}

	.textblock-1 h1,
	.textblock-1 h2,
	.textblock-1 h3 {
		font-size:18px;
		line-height:20px;
	}
	
	.textblock-1 p {
		font-size:16px;
		line-height:28px;
	}
	
	.textblock-1 ul {
		font-size:16px;
		line-height:28px;
	}
	
	.textblock-2 p {
		font-size:16px;
		line-height:28px;
	}

}
