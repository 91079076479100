@import "default.settings_breakpoints";

.include__instagram {
	float:left;
	width:100%;
	margin-top:20px;
	background-color:#fff;

	@include md {
		margin-top:20px;
	}

	h2 {
		color:#000;
		padding-bottom:20px;

		&:first-child {
			@include md {
				padding:0;
			}
		}
		
		&:nth-child(1) {
			@include md {
				
			}
		}

		&.check {
			padding-bottom:0;
		}

	}

	.instagram-feed {

		@include md {
			float:left;
			width:100%;
			margin-top:10px;
		}

		.item {
			display:inline-block;
			width:300px;
			margin-left:20px;
			margin-right:20px;

			@include lg {
				width:200px;
			}
			@include md {
				width:50%;
			}
			@include md {
				width:45%;
				margin-left:0;
				margin-right:6px;
			}

			&:nth-child(2) {
				@include md {
					margin-right:0;
				}
			}
			&:nth-child(4) {
				@include md {
					margin-right:0;
				}
			}

			a {
				float:left;
				width:300px;
				height:300px;
				background-size:contain;
				background-position:center;
				background-repeat:no-repeat;

				@include lg {
					width:200px;
					height:200px;
				}
				@include md {
					width:100%;
					height:auto;
					padding-top:100%;
				}
				@include md {	
					width:100%;
					height:auto;
					padding-top:100%;
				}

			}

			.sub {
				clear:both;
				float:left;
				width:100%;
				height:90px;
				font-size:15px;
				line-height:20px;
				margin-top:10px;
				color:#000;
				text-align:center;

				@include lg {
					font-size:11px;
				}
				@include md {
					margin-bottom:20px;
					line-height:17px;
					height:20vw;
				}

				@include sm {
					height:auto;
				}

			}

			.likes {
				clear:both;
				float:left;
				width:100%;
				margin-top:5px;
				color:#000;
				text-align:center;

				.ic {
					display:inline-block;
					width:auto;
					margin:5px;
					margin-left:10px;
					margin-right:5px;
					color:#000;

					 .icon {
						float:left;
						font-size:16px;
						margin-top:1px;
						color:#888;
					}

					.label {
						float:left;
						margin-left:5px;
					}

				}

			}

		}

		.instagram-item-3 {
			@include md {
				display:none;
			}
		}
		.instagram-item-4 {
			@include md {
				display:none;
			}
		}

	}

	.socials {
			float:left;
			width:100%;
			padding-bottom:20px;

			a {
				display:inline-block;

				.circle {
					display:inline-block;
					position:relative;
					width:50px;
					height:50px;
					margin:10px;
					border:4px solid #ff6400;
					border-radius:50%;
					background-color:#000;

					&:hover {
						background-color:#000;
					}

					.icon {
						position:absolute;
						font-size:25px;
						color:#fff;

						&.icon-facebook {
							top:9px;
							left:8px;
						}
						&.icon-instagram {
							top:8px;
							left:9px;
						}
						&.icon-youtube {
							top:7px;
							left:9px;
						}

					}
				
				}

			}

		}

}