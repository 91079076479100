/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2019
	10-04-2018
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

.helper-video {
	display:inline-block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
	.helper-video iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
  
}