/* NAV */
/* NAV */
/* NAV */
/* NAV */

nav {
	float:left;
	width:100%;
	text-align:left;
}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {

  
}