.page.content--binnenpagina.content--klantervaringen {
}

	.page.content--binnenpagina.content--klantervaringen .ervaring {
		float:left;
		width:100%;
		padding:20px;
		margin-bottom:20px;
		background-color:#e7edee;
		border-radius:20px;
		color:#000;
	}
	
		.page.content--binnenpagina.content--klantervaringen .ervaring .payoff {
			float:left;
			width:100%;
			font-size:20px;
			line-height:25px;
			font-family:'Brown';
			font-weight:bold;
		}
		
		.page.content--binnenpagina.content--klantervaringen .ervaring img {
			float:left;
			width:100%;
			margin-top:10px;
			border-radius:10px;
		}
		
		.page.content--binnenpagina.content--klantervaringen .ervaring .text {
			float:left;
			width:100%;
			margin-top:10px;
			padding-bottom:20px;
			font-size:16px;
			line-height:25px;
			border-bottom:1px solid #888;
		}
		
		.page.content--binnenpagina.content--klantervaringen .ervaring .name {
			float:left;
			width:100%;
			margin-top:10px;
		}
		.page.content--binnenpagina.content--klantervaringen .ervaring .stars {
			float:left;
			margin-top:5px;
			margin-bottom:5px;
			width:100%;
			height:20px;
			background-image:url(../img/static/google-stars.png);
			background-repeat:no-repeat;
			background-position:left center;
		}
		.page.content--binnenpagina.content--klantervaringen .ervaring .done {
			margin-top:5px;
		}
		.page.content--binnenpagina.content--klantervaringen .ervaring .city {
			margin-top:5px;
		}
		
		
@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
	.block-klantervaringen .slider .icon-next {
		display:none;
	}
	.block-klantervaringen .slider .icon-prev {
		display:none;
	}
	
}

@media (max-width: 768px) {
	
	.block-klantervaringen .slider .item {
		height:inherit;
	}
	
	.block-klantervaringen .slider .item .inside .bottom .left {
		width:100%;
	}
	.block-klantervaringen .slider .item .inside .bottom .right {
		margin-left:0;
		width:100%;
	}
	
}