.page.content--binnenpagina .form .element-row-checkbox {
	float:left;
	width:50px;
}

.page.content--binnenpagina .form .label-checkbox {
	float:left;
	margin-top:5px;
	width:calc(100% - 50px);
}

	.page.content--binnenpagina .form .label-checkbox .small {		
		font-size:12px;
		color:#888;
	}

.page.content--binnenpagina .extramargebottom {
	margin-top:20px;
}

.page.content--binnenpagina .form .ep {
	margin-bottom:5px;
}
		
@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {

	.page.content--binnenpagina .form .label-checkbox {
		float:left;
		margin-top:5px;
	}

	.page.content--binnenpagina .extramargebottom {
		margin-top:0;
	}

	.page.content--binnenpagina .form .label-checkbox .small {
		width:calc(100% - 50px);
	}
	
}