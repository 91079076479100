/*
Version 0.101

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

/* PAGELOADER */
/* PAGELOADER */
/* PAGELOADER */
/* PAGELOADER */

.page-loader {
	float:left;
	width:100%;
	background-repeat:no-repeat;
	background-position:center top;
}

/* PRELOADER */
/* PRELOADER */
/* PRELOADER */
/* PRELOADER */

.loader {
	position:fixed;
	top:0;
	left:0;
	width:0;
	height:2px;
	background-color:#000;
	z-index:9999;
}

/**
LazySizes Blur
**/

.blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 400ms, -webkit-filter 400ms;
}

.blur.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
  
}
