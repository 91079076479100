.page.content--binnenpagina .nomarge {
	margin-left:-15px;
	margin-right:-15px;
}

.page.content--binnenpagina .nmt {
	margin-top:0;
}

		
@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
}