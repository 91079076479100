.page.content--binnenpagina {
	float:left;
	width:100%
}

	.page.content--binnenpagina .content {
		float:left;
		width:100%;
		padding-bottom:40px;
		background-color:#fff;
	}
	
		.page.content--binnenpagina .content .title {
			position:relative;
			float:left;
			width:100%;
			background-color:#004600;
		}

			.page.content--binnenpagina .content .title .image {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background-image:url(../img/static/header-title.webp);
				background-size:cover;
				background-position:center;
				opacity:1;
				mix-blend-mode: multiply;
			}


		
			.page.content--binnenpagina .content .title h1 {
				font-family:'Brown',arial;
				color:#fff;
				padding-top:10px;
				padding-bottom:10px;
			}
	
		.page.content--binnenpagina .content .textblock-1 p,
		.page.content--binnenpagina .content .textblock-1 .p {
			color:#000;
		}

		.page.content--binnenpagina .content .textblock-1 ul,
		.page.content--binnenpagina .content .textblock-1 ol {
		   list-style: none;
		   counter-reset: item;
		   margin-top: 20px;
		   margin-bottom: 10px;
		}
		
		.page.content--binnenpagina .content .textblock-1 li {
			position: relative;
			padding-left: 2em;
			/* text-indent: -1em; */
			margin-bottom: 10px;
			counter-increment: list-counter;
		   counter-increment: list-counter;
		 }

		.page.content--binnenpagina .content .textblock-1 li:before {
			position: absolute;
			left: 0;
			top: 0;
			background: #23a638;
			text-align: center;
			border-radius: 100%;
			color: white;
			width: 26px;
			height: 26px;
			content: counter(list-counter);
			font-weight: bold;
			margin-right: 0.5em;
		}

	.page.content--binnenpagina .content .container {
		clear:both;
	}
		.page.content--binnenpagina .content .container.container-2 {
			padding-top:40px;
		}
		
			.page.content--binnenpagina .content .container.container-2 .quote {
				margin-top:60px;
				margin-bottom:40px;
				width:75%;
				height:250px;
			}
				.page.content--binnenpagina .content .container.container-2 .quote .text {
					width:340px;
				}
				
				.page.content--binnenpagina .content .container.container-2 .quote .google {
					width:340px;
				}
				
	.page.content--binnenpagina .content img {
		margin-bottom:20px;
		border-radius:10px;
	}
	
	.page.content--binnenpagina .block-recentopgeleverd {
		margin-bottom:40px;
	}
	
	.page.content--binnenpagina table.contact {
		float:left;
		width:100%;
		border:1px solid #ccc;
		padding:10px;
		background-color:#F5F5F5;
		line-height:25px;
	}
	
	.page.content--binnenpagina table.contact td:first-child {
		width:100px;
	}
	
	.page.content--binnenpagina table.contact tr td {
		padding-top:5px;
		padding-bottom:5px;
		
	}
	
	.page.content--binnenpagina table.contact tr.nb {
		
	}
	
	.page.content--binnenpagina ul.hoewerkthet {
		list-style-type:none;
		padding:0;
	}
	
	.page.content--binnenpagina ul.hoewerkthet li {
		clear:both;
		float:left;
		width:100%;
		margin-bottom:20px;
	}
	
	.page.content--binnenpagina ul.hoewerkthet li .left {
		float:left;
		width:40px;
	}
	
		.page.content--binnenpagina ul.hoewerkthet li .left .number {
			width:40px;
			height:40px;
			border-radius:50%;
			background-color:#23a638;
		}
		
		.page.content--binnenpagina ul.hoewerkthet li .left .number .inside {
			float:left;
			font-size:24px;
			font-family:'Brown',arial;
			color:#fff;
			margin-top:4px;
			width:100%;
			text-align:center;
		}
		
	
	.page.content--binnenpagina ul.hoewerkthet li .right {
		float:left;
		margin-left:20px;
		width:600px;
	}
	
	
	
		
@media (max-width: 1200px) {
	
	.page.content--binnenpagina .content .container.container-2 .quote .google, 
	.page.content--binnenpagina .content .container.container-2 .quote .text {
		width:270px;
	}
	
}

@media (max-width: 992px) {
	
	.page.content--binnenpagina .content .container.container-2 .quote {
		width:100%;
	}
	
	.page.content--binnenpagina ul.hoewerkthet li .right {
		float:left;
		margin-left:20px;
		width:400px;
	}
	
}

@media (max-width: 768px) {

	.page.content--binnenpagina .content .textblock-1 ul,
	.page.content--binnenpagina .content .textblock-1 ol {
	   padding-left:10px;
	}

	.page.content--binnenpagina .content .textblock-1 li::before {
		float: left;
		margin-top: -10px;
	  	margin-right: 10px;
	}
	
	.page.content--binnenpagina .content .container.container-2 {
		padding:20px;
	}
	
	.page.content--binnenpagina .content img {
		margin-top:20px;
		margin-bottom:0;
	}
	
	.page.content--binnenpagina .content .container.container-2 .quote {
		width:100%;
		height:auto;
	}
	
	.page.content--binnenpagina .content  .title h1 {
		font-size:20px;
	}
	
	.page.content--binnenpagina .content .container.container-2 .quote .text {
		position:inherit;
		float:left;
		width:100%;
	}
	
	.page.content--binnenpagina .content .container.container-2 .quote .google {
		position:inherit;
		float:left;
		width:100%;
	}
	
	.page.content--binnenpagina .content .container.container-2 .quote .person {
		bottom:0;
		position:inherit;
		float:left;
		width:100%;
	}
	
	.page.content--binnenpagina ul.hoewerkthet li .right {
		float:left;
		margin-left:20px;
		width:70%;
	}
	
}