.popup--feedback {
	float:left;
	width:600px;
}

	.popup--feedback .content {
		float:left;
		width:100%;
		color:#000;
		background-color:#fff;
		padding:80px;
		text-align:left;
	}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {

	.popup--feedback {
		width:250px;
	}
	
	.popup--feedback .content {
		padding:0;
		padding-top:20px;
		padding-bottom:20px;
	}		
	
}