/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	21-05-2018
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */
/* COOKIEWALL ****************************************************************************************** */

.popup--cookiewall {
	float:left;
	width:600px;
	padding:20px;
	background-color:#fff;
}

	.popup--cookiewall .popup .content {
		margin-top:0 !important;
	}
	
		.popup--cookiewall .textblock h2 {
			padding:0;
			margin:0;
		}

	.popup--cookiewall .left {
		float:left;
		width:150px;
	}

		.popup--cookiewall .left .option {
			float:left;
			width:100%;
			padding:5px;
			margin-top:5px;
			background-color:#e1e1e1;
		}
		
		.popup--cookiewall .left input {
			float:left;
			padding:5px;
			margin-top:2px;
		}
		
		.popup--cookiewall .left .title {
			float:left;
			margin-left:10px;
			margin-top:-3px;
			cursor:pointer;
		}
		
		.popup--cookiewall .left .title:hover {
			text-decoration:underline;
		}
		
		.popup--cookiewall .left .submit {
			float:left;
			width:100%;
			margin-top:10px;
			background-color:#5A9C49;
			color:#fff;
			padding:5px;
			font-size:12px;
			line-height:18px;
			font-weight:bold;
			text-align:center;
			cursor:pointer;
		}
	
	.popup--cookiewall .right {
		float:left;
		width:350px;
		margin-top:5px;
		margin-left:20px;
		padding:20px;
		font-size:14px; 
		line-height:28px;
		border:1px solid #e1e1e1;
	}
	
		.popup--cookiewall .right h2 {
			font-size:18px;
			font-weight:bold;
			line-height:20px;
			margin:0;
			padding:0;
			margin-bottom:5px;
		}
		
		.popup--cookiewall .right .tab {
			display:none;
		}
	
	.popup--cookiewall .rest {
		clear:both;
		float:left;
		margin-top:20px;
		width:100%;
	}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	
	.popup--cookiewall {
		width:290px;
	}
	
	.popup--cookiewall .left {
		width:100%;
	}
	
	.popup--cookiewall .right { 
		width:100%;
		margin-top:15px;
		margin-left:0;
	}
	
}

@media (max-width: 768px) {
	
}