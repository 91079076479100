.block-producten {
	float:left;
	width:100%;
	padding-bottom:40px;
	background-color:#fff;
}

	.block-producten .product-item {
		float:left;
		width:100%;
		margin-bottom:20px;
	}
	
		.block-producten .product-item .icon {
			float:left;
			width:100%;
			height:240px;
			background-color:#e7edee;
			border-radius:20px;
			background-repeat:no-repeat;
			background-position:center;
			background-size:70%;
		}
		
			.block-producten .product-item .icon.kozijnen {
				background-image:url(../img/static/icon-kozijnen-dicht.png);
			}
			.block-producten .product-item .icon.kozijnen:hover {
				background-image:url(../img/static/icon-kozijnen-open.png);
			}
			
			.block-producten .product-item .icon.deuren {
				background-image:url(../img/static/icon-voordeur-dicht.png);
			}
			.block-producten .product-item .icon.deuren:hover {
				background-image:url(../img/static/icon-voordeur-open.png);
			}
			
			.block-producten .product-item .icon.tuindeuren {
				background-image:url(../img/static/icon-tuindeuren-dicht.jpg);
			}
			.block-producten .product-item .icon.tuindeuren:hover {
				background-image:url(../img/static/icon-tuindeuren-open.jpg);
			}
			
			.block-producten .product-item .icon.schuifpuien {
				background-image:url(../img/static/icon-schuifpui-dicht.svg);
			}
			.block-producten .product-item .icon.schuifpuien:hover {
				background-image:url(../img/static/icon-schuifpui-open.svg);
			}
			
			.block-producten .product-item .icon.gevelbekleding {
				background-image:url(../img/static/icon-gevelbekleding-dicht.png);
				background-size:70%;
			}
			.block-producten .product-item .icon.gevelbekleding:hover {
				background-image:url(../img/static/icon-gevelbekleding-open.png);
			}
			
			.block-producten .product-item .icon.dakkapelrenovaties {
				background-image:url(../img/static/icon-dakkapel-dicht.png);
				background-size:70%;
			}
			.block-producten .product-item .icon.dakkapelrenovaties:hover {
				background-image:url(../img/static/icon-dakkapel-open.png);
			}
		
		.block-producten .product-item .label {
			float:left;
			width:100%;
			margin-top:10px;
			margin-bottom:20px;
			text-align:center;
			font-family:'Brown',arial;
			color:#000;
			font-size:30px;
			font-weight:bold;
			text-decoration:none;
		}

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
	.block-producten .product-item {
		margin-bottom:0;
	}
	
	.block-producten .product-item .label {
		font-size:13px;
	}
	
	.block-producten .product-item .icon {
		height:130px;
	}
	
}

@media (max-width: 768px) {
	
}