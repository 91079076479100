.page.content--landing {
}

	.page.content--landing .hero {
		position:relative;
		float:left;
		width:100vw;
		height:100dvh;
		background-color:#fff;
		background-image:url(../img/static/spotlight-frontpage.jpg);
		background-size:cover;
		background-position:center;
		text-align:center;
		overflow:hidden;
	}

		.page.content--landing .hero .logo {
			display:inline-block;
			position:relative;
			width:300px;
			height:80px;
			background-color:#fff;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			box-shadow:0 0 10px rgba(0,0,0,0.1);
		}

			.page.content--landing .hero .logo svg {
				position:absolute;
				width:70%;
				left:50%;
				top:50%;
				transform:translate(-50%,-50%);
			}

			.page.content--landing .hero .logo svg .st0{
				fill:#696869;
			}
			.page.content--landing .hero .logo svg .st1{
				fill:#55B8EA;
			}
			.page.content--landing .hero .logo svg .st2{
				fill:#00A536;
			}

		.page.content--landing .hero .center {
			position:absolute;
			top:50%;
			left:50%;
			width:600px;

			transform:translate(-50%,-100%);
		}

			.page.content--landing .hero .center .text {
				float:left;
				width:100%;
			}

				.page.content--landing .hero .center .text h1 {
					float: left;
					margin:0;
					padding:0;
					font-family: Brown,arial;
					font-weight: 700;
					width:100%;
					text-align:center;
					font-size:2vw;
					line-height:2vw;
					color: #23a638;
				}

				.page.content--landing .hero .center .text h2 {
					float: left;
					margin:0;
					padding:0;
					margin-top:0.5vw;
					font-family: Brown,arial;
					font-weight: 700;
					width:100%;
					text-align:center;
					font-size:1vw;
					line-height:1vw;
					color: #232323;
				}

				.page.content--landing .hero .center .interaction {
					float:left;
					width:100%;
					margin-top:40px;
					background-color:#fff;
					border-radius:10px;
					padding:20px;
					box-shadow:0 0 10px rgba(0,0,0,0.1);
				}

					.page.content--landing .hero .center .interaction .question {
						float:left;
						width:100%;
						font-size:20px;
						color:#000;
					}

						.page.content--landing .hero .center .interaction .question h3 {
							margin:0;
							padding:0;
						}

					.page.content--landing .hero .center .interaction .answers {
						float:left;
						width:100%;
						margin-top:20px;
					}

						.page.content--landing .hero .center .interaction .answers .answer {
							display:inline-block;
							margin-left:5px;
							margin-right:5px;
							width:150px;
							background-color:#23a638;
							padding:10px;
							border-radius:10px;
							cursor:pointer;
						}
							.page.content--landing .hero .center .interaction .answers .answer:hover {
								background-color:#20782e;
							}
							.page.content--landing .hero .center .interaction .answers .answer:hover .label {
								color:#fff;
							}

						.page.content--landing .hero .center .interaction .answers .answer .icon {
							float:left;
							width:20px;
							height:10px;
							margin-top:0px;
						}
							.page.content--landing .hero .center .interaction .answers .answer .icon svg {
								width:20px;
								height:20px;
								fill:#fff;
							}

						.page.content--landing .hero .center .interaction .answers .answer .label {
							float:left;
							margin-left:10px;
							font-weight:bold;
						}

		.page.content--landing .hero .slideshow {
			position:absolute;
			bottom:0;
			height:20%;
			width:100%;
			background-color:#2db8eb;
		}

			.page.content--landing .hero .slideshow .slide {
				float:left;
				width:20%;
				height:225px;
				background-color:#fff;
				margin-top:-100px;
				transform:rotate(-2deg);
				box-shadow:0 0 10px rgba(0,0,0,0.1);
				background-size:cover;
				background-position:center;
				text-align:center;
				border-radius:10px;
			}

				.page.content--landing .hero .slideshow .slide.portrait {
					width:225px;
					height:300px;
					margin-top:-150px;
					transform:rotate(2deg);
				}


.adviseur {
	float:left;
	width:100%;
	margin-top:40px;
}

	.adviseur .text {
		float:left;
		margin-top:10px;
		width:150px;
		text-align:center;
	}

		.adviseur .text h1 {
			float:left;
			width:100%;
			marign-top:10px;
			font-size:16px;
			line-height:20px;
		}
		.adviseur .text p {
			float:left;
			width:100%;
			marign-top:10px;
			margin:0;
			padding:0;
			font-size:11px;
		}



	.adviseur .image {
		float:left;
		width:80px;
		height:80px;
		background-image:url('../img/static/team/1_rood-01.svg');
		background-repeat:no-repeat;
		background-position:center;
		background-size:contain;
	}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {

	.page.content--landing .hero .logo {
		width:150px;
		height:40px;
	}

	.page.content--landing .hero .center .text h1 {
		font-size:24px;
		line-height:24px;
	}

	.page.content--landing .hero .center .text h2 {
		font-size:16px;
		line-height:16px;
	}

	.page.content--landing .hero .center { 
		width:280px;
		transform:translate(-50%,-70%);
	}

		.page.content--landing .hero .center .interaction .question h3 {
			font-size:13px;
		}

	.page.content--landing .hero .center .interaction .answers .answer {
		float:left;
		width:100%;
		margin-bottom:5px;
		margin-left:0;
	}


	.page.content--landing .hero .slideshow .slide {
		width:25%;
		height:100px;
		margin-top:-20px;
	}

		.page.content--landing .hero .slideshow .slide.portrait {
			width:25%;
			height:70px;
			margin-top:35px;
		}


	.adviseur {
		margin-top:10px;
	}
}