.popup--adviesgesprek {
	float:left;
	width:700px;
}

	.popup--adviesgesprek .content {
		float:left;
		width:100%;
		background-color:#fff;
		padding:20px;
		border-radius:4px;
		text-align:left;
		color:#000;
	}
	
	.popup--adviesgesprek .content h2 {
		font-size:20px;
		float:left;
		width:100%;
		padding:0;
		margin:0;
		padding-bottom:5px;
		padding-top:5px;
		color:#000;
	}
	
	.popup--adviesgesprek .content h2.thanks {
		margin-bottom:10px;
		padding:9px;
	}
	
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.popup--adviesgesprek {
		width:300px;
	}
	
}