/*
Version 0.125

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2019
	31-05-2019
	- [ADDED] .disabled is added.
	22-05-2019
	- [CHANGE] Made the iOS option commented out, because in other browsers the arrow-down is gone.
	16-05-2019
	- [CHANGE] Some thing added for iOS rounder corners.
2018
	11-06-2018
	- [CHANGE] Link to loading.svg was wrong, fixed now.
2017
	28-11-2017
	- [CSS] input[type="number'] added.
	- [CSS] .indicator css is default, custom styling is moved to global.css
	03-11-2017
	- [TINYMCE] Added new style for TinyMCE Editor
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

input[type="text"],
input[type="password"],
input[type="number"],
textarea,
select {
	font-size:14px;
	line-height:20px;
	width:100%;
	padding:10px 15px;
	color:#000;
	background-color:#fff;
	border:0;
	/* -webkit-appearance: none;  iOS rutn off border-radius */
	border-radius: 0;			/* iOS rutn off border-radius */
}

	/**
	Hide the nummber arrows
	**/
	input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	 
	input[type="number"] {
		-moz-appearance: textfield;
	}

textarea {
	overflow:auto;
	resize:none;
}

input[type="button"] {
	font-size:14px;
	padding:5px 10px;
	color:#fff;
	background-color:#000;
	border:0;
	text-align:center;
	cursor:pointer;
}

.form {
	float:left;
	width:100%;
}

.form .form-row {
	float:left;
	width:100%;
}

.form .element-row {
	position:relative;
	float:left;
	width:100%;
	margin-bottom:5px;
	color:#000;
}

	.form .element-row .form-input.disabled {
		background-color:#ccc;
	}

	.form .element-row .form-input {
		float:left;
		box-shadow:inset 0 0 7px -4px #000;
		border:0;
		background-color:#ffff;
		padding:11px;
		padding-left:10px;
		padding-right:10px;
		font-size:16px;
		border:1px solid #888;
	}
	
	/**
	Checkbox: default
	**/
	.form .element-row .checkbox .box {
		float:left;
		width:15px;
		margin-top:5px;
	}
	.form .element-row .checkbox span {
		float:left;
		margin-left:10px;
		width:80px;
	}
	
	/**
	Default radiobuttons
	**/
	.form .element-row .radiobutton .radio {
		float:left;
		width:15px;
		margin-top:5px;
	}
	.form .element-row .radiobutton span {
		float:left;
		margin-left:10px;
		width:80px;
	}
	
	.radiobutton_specific-element:last-child {
		margin-bottom:0;
	}
	
	/**
	Textarea: default
	**/
	.form .element-row textarea {
		height:200px;
	}
	
		.form .element-row .upload .left {
			float:left;
			width:100%;
		}
		
		.form .element-row .upload .right {
			float:left;
			width:100%;
			margin-left:0;
		}
		
			.form .element-row .upload .right input {
				float:left;
				width:100%;
				margin-top:10px;
				margin-bottom:5px;
				margin-left:0;
			}
		
		.form .element-row .upload .upload-process {
			display:none;
		}
		
		.form .element-row .upload .upload-info {
			float:left;
		}
		
			.form .element-row .upload .upload-info span {
				float:left;
				width:100%;
				padding:10px;
			}
	
	/**
	Submitbutton: default
	**/
	.form .form-button,
	.form .form-submit {
	}
	
	.form .form-submit {
		text-decoration:none;
		cursor:pointer;
	}
	
		.form .form-submit:hover {
			text-decoration:underline;	
		}
	
	.form .form-submit.active {
		padding-left:30px !important;
		padding-left:50px;
		background-image:url(../img/static/assets/loading.svg);
		background-position:10px;
		background-repeat:no-repeat;
		background-size:13%;
	}
	
	.form .form-button:hover,
	.form .form-submit:hover {
	}
	
	.form .form-input.no-bottom-marge {
		margin-bottom:0;
	}
	
	.form .element-row .indicator {
		position:absolute;
		top:0;
		right:0;
		height:100%;
		width:4px;
	}
	
	.form .element-row .indicator.ok {
	}
	
.form .form-block {
	position:relative;
	float:left;
	width:100%;
	margin-bottom:20px;
	padding:20px;
	background-color:#eaeaea;
}

/*
TinyMCE Editor styles
*/
.mce-path {
    display: none !important;
}

/*
Image Gallery
*/
.form .form-row .form-image-gallery {
}

.form .form-row .form-image-gallery .image-gallery-image-upload {
	float:left;
	margin:10px;
	width:180px;
	text-align:center;
	margin-left:0;
	padding:5px 10px;
	color:#fff;
	font-weight:bold;
	background-color:#000;
	cursor:pointer;
}

.form .form-row .form-image-gallery .upload-process {
	display:none;
	font-size:14px;
}

.form .form-row .form-image-gallery .images {
	clear:both;
	float:left;
	width:100%;
}

.form .form-row .form-image-gallery .images .image {
	float:left;
	position:relative;
	border:5px solid #ccc;
	background-color:#e1e1e1;
	width:180px;
	height:180px;
	margin-bottom:10px;
	margin-right:10px;
	background-repeat:no-repeat;
	background-position:center;
	background-size:cover;
}

	.form .form-row .form-image-gallery .images .image .image-gallery-image-delete {
		position:absolute;
		right:5px;
		top:5px;
		line-height:13px;
		font-size:16px;
		padding:5px;
		background-color:#888;
		color:#fff;
		cursor:pointer;
		border:1px solid #999;
	}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
	
	.form .form-submit {
		font-size:10px;
	}
	
	.form .element-row .form-input {
		font-size:13px;
	}
  
}
